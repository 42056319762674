<script setup>
	const props = defineProps({
		anchorId: {
			type: String,
			required: false,
			default: '',
		},
		image: {
			type: Object,
			required: false,
		},
		imageAlignment: {
			type: Boolean,
			required: false,
		},
		headline: {
			type: String,
			required: false,
		},
		body: {
			type: String,
			required: false,
		},
		button: {
			type: Object,
			required: false,
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});

	const { anchorId, image, imageAlignment, headline, body, button } = toRefs(props);
</script>

<template>
	<section :id="anchorId" class="block block-aligned-fifty-fifty bg-gray-100" tabindex="-1">
		<div :class="{ 'image-right': imageAlignment }" class="container">
			<figure class="image-wrapper">
				<MessImage v-if="image" :src="image.src" :alt="image.alt" />
			</figure>
			<FragmentsCardBox
				class="card"
				:headline="{ html: headline, class: 'h3', tag: 'h2' }"
				:copy="body"
				:button="button"
				padding="large"
				background="white"
			/>
		</div>
	</section>
</template>

<style lang="scss">
	.block-aligned-fifty-fifty {
		.container {
			@media (min-width: $mobile) {
				display: flex;
				justify-content: center;

				&.image-right {
					flex-direction: row-reverse;
				}
			}
		}

		.image-wrapper {
			background-color: var(--white);

			@media (min-width: $mobile) {
				position: relative;
				flex: 1 1 40%;
				height: auto;
				padding: 0;

				.mess-image {
					width: 100%;
					height: auto;
				}
			}

			@media (min-width: $tablet) {
				max-width: 574px;
				flex: 1 1 calc(100% - 574px);
			}
		}

		.card {
			background-color: var(--white);

			@media (min-width: $mobile) {
				flex: 1 1 60%;
			}

			@media (min-width: $tablet) {
				flex: 1 1 786px;
			}

			.copy {
				.mess-link {
					display: unset;
					padding: 0;
					margin-top: 0;
					font-size: inherit;
					font-weight: inherit;
					text-transform: none;
				}
			}
		}
	}
</style>
